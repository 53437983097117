import "./App.css";
import Home from "./page/Home";
import MobileErrorPage from "./page/MobileErrorPage";
import { useMediaQuery } from "@mui/material";
import NoInternet from "./page/NoInternet";
import { useEffect, useState } from "react";

function App() {
  const isTvScreen = useMediaQuery("(min-width: 1000px)");
  const [status, setStatus] = useState(true);
  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);
  return (
    <div>
      {!isTvScreen ? <MobileErrorPage /> : !status ? <NoInternet /> : <Home />}
    </div>
  );
}

export default App;
